import * as React from 'react';

import styled from '@emotion/styled';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import Page from '../components/Page';
import Container from '../components/Container';
import IndexLayout from '../layouts';
import {
  StyledRankCell,
  StyledRankCellHeader,
  StyledTickerNameCell,
  StyleLastUpdatedBlock,
  TrendsTable,
  fixFormattedPrice,
  PriceChangePercent,
  StyledMoneyCell,
  StyledMoneyCellHeader,
  StyledMoneyPercentHeader,
  StyledPriceCell,
  StyledPriceChangeCell,
  StyledPriceNegativeNumber,
  StyledPricePositiveNumber,
  StyledMoneyCellLeft,
  StyledMoneyCellHeaderLeft,
  StyledPriceCellHeader,
  TickerSymbolAndName,
  StyledExchangeCellHeader,
  StyledExchangeCell,
  formatExchangeName,
  StyledPositiveNumber,
  StyledNegativeNumber
} from '../components/TrendsTable';
import { PageHeader } from '../components/PageHeader';
import { abbreviateNumber } from '../utils/numbers';
import { GammaSection } from '../components/GammaSection';
import Tables from '../components/Tables';

interface HomeTemplateProps {
  pageContext: any;
}

const StyledEpsCell = styled(Tables.Cell)`
  font-size: 0.9em;
`;

const StyledEpsEstimateCell = styled(Tables.Cell)`
  text-align: right;
  font-size: 0.95em;

  span {
    font-weight: normal !important;
  }
`;

const StyledParagraphBetweenTables = styled.p`
  margin-top: -20px;
`;

export function PercentChangePercent({ value, prev }: NumberProps) {
  const diff = (value - prev) | 0;
  if (diff === 0) return null;
  return (
    <>
      {value > prev ? (
        <StyledPricePositiveNumber title={`Up from ${prev}% to ${value}%`}>(+{diff}%)</StyledPricePositiveNumber>
      ) : (
        <StyledPriceNegativeNumber title={`Down from $${prev}% to $${value}%`}>({diff}%)</StyledPriceNegativeNumber>
      )}
    </>
  );
}

const StyledEarningsRow = styled.tr`
  &:nth-of-type(even) td {
    background: ${p => p.theme.colors.cardAlt} !important;
  }
`;

const HomeTemplate: React.FC<HomeTemplateProps> = ({
  pageContext: { earnings, tomorrow, tomorrowOgs, daily, shorts, bsb, wsb, stocksInvesting, pennies, gammas, renderTime }
}) => (
  <IndexLayout>
    <Page>
      <Container>
        <Helmet title="wsbdaily - Where you find Tomorrow’s Hot Stocks Yesterday!" />
        <PageHeader time={renderTime} />
        <h1>Trending on Reddit</h1>
        <p>
          Top stock tickers popular on the Reddit <a href="https://www.reddit.com/r/wallstreetbets">wallstreetbets</a> community, of{' '}
          {abbreviateNumber(wsb.totalSubscribers)} members, over the last {(wsb.days || 1) * 24}-hours.
        </p>
        <TrendsTable data={wsb} count={35} />
        <StyledParagraphBetweenTables>
          Top stock tickers popular across the Reddit <a href="https://www.reddit.com/r/stocks">stocks</a> and{' '}
          <a href="https://www.reddit.com/r/investing">investing</a> communities, of {abbreviateNumber(stocksInvesting.totalSubscribers)}{' '}
          members total, over the last {(stocksInvesting.days || 1) * 24}-hours.
        </StyledParagraphBetweenTables>
        <TrendsTable data={stocksInvesting} />
        <StyledParagraphBetweenTables>
          Top stock tickers popular on the Reddit <a href="https://www.reddit.com/r/pennystocks">pennystocks</a> community, of{' '}
          {abbreviateNumber(pennies.totalSubscribers)} members, over the last {(pennies.days || 1) * 24}-hours. See our{' '}
          <Link to="/penny/">Pennystock</Link> homepage for all penny stock market trends.
        </StyledParagraphBetweenTables>
        <TrendsTable data={pennies} />
        <StyledParagraphBetweenTables>
          Top stock tickers popular on the Reddit <a href="https://www.reddit.com/r/baystreetbets">Baystreetbets</a> (Canadian WSB)
          community, of {abbreviateNumber(bsb.totalSubscribers)} members, over the last {(bsb.days || 1) * 24}-hours. See our{' '}
          <Link to="/canada/">Canadian</Link> homepage for all the Canadian market trends.
        </StyledParagraphBetweenTables>
        <TrendsTable data={bsb} />
        <TrendsTable heading="Tomorrow’s Moves Trends" data={tomorrow} count={20} />
        <p style={{ marginTop: -20 }}> </p>
        <TrendsTable data={tomorrowOgs} count={20} />
        <TrendsTable
          heading={`${daily.post?.title.startsWith('Weekend') ? 'Weekend' : 'Daily'} Discussion Trends`}
          data={daily}
          count={25}
        />
        <h1>Upcoming Earnings</h1>
        <p>Upcoming earnings dates for the top mentioned tickers.</p>
        <Tables.Table>
          <Tables.Head>
            <tr>
              <Tables.HeadCell>When</Tables.HeadCell>
              <Tables.HeadCell>Time</Tables.HeadCell>
              <Tables.HeadCell>Symbol (Name)</Tables.HeadCell>
              <StyledExchangeCellHeader title="The exchange the ticker trades on">&nbsp;</StyledExchangeCellHeader>
              <Tables.HeadCell style={{ textAlign: 'right' }}>EPS Estimated</Tables.HeadCell>
            </tr>
          </Tables.Head>
          <Tables.Body>
            {earnings.earnings
              .filter((_, index) => index < 15)
              .map((t, index) => (
                <StyledEarningsRow key={`${t.dateLong}_${t.symbol}`}>
                  <StyledEpsCell>{t.dateLong}</StyledEpsCell>
                  <StyledEpsCell>{t.time.toUpperCase()}</StyledEpsCell>
                  <StyledTickerNameCell>
                    <TickerSymbolAndName ticker={t} />
                  </StyledTickerNameCell>
                  <StyledExchangeCell title={`${t.exchangeName || ''} (${t.exchange || ''})`}>
                    {formatExchangeName(t.exchangeName || '')}
                  </StyledExchangeCell>
                  <StyledEpsEstimateCell>
                    {t.epsEstimated !== null && (
                      <>
                        {t.epsEstimated > 0 ? (
                          <StyledPositiveNumber>+{t.epsEstimated.toFixed(2)}</StyledPositiveNumber>
                        ) : (
                          <StyledNegativeNumber>{t.epsEstimated.toFixed(2)}</StyledNegativeNumber>
                        )}
                      </>
                    )}
                  </StyledEpsEstimateCell>
                </StyledEarningsRow>
              ))}
          </Tables.Body>
        </Tables.Table>
        <StyleLastUpdatedBlock>Last Updated: {earnings.lastUpdatedEst}</StyleLastUpdatedBlock>
        <h1>Short Interest</h1>
        <p>
          Stocks with high short interest are often very volatile and prices can potentially move up very quickly as traders with open short
          positions move to cover (known as a short squeeze, see GME and VW).
        </p>
        <Tables.Table>
          <Tables.Head>
            <tr>
              <StyledRankCellHeader>#</StyledRankCellHeader>
              <Tables.HeadCell>Symbol (Name)</Tables.HeadCell>
              <StyledExchangeCellHeader title="The exchange the ticker trades on">&nbsp;</StyledExchangeCellHeader>
              <StyledMoneyCellHeaderLeft title="The Short Interest in the stock">Short %</StyledMoneyCellHeaderLeft>
              <StyledMoneyCellHeader>Float</StyledMoneyCellHeader>
              <StyledMoneyCellHeader>Outstanding</StyledMoneyCellHeader>
              <StyledPriceCellHeader title="The last closing price">Price</StyledPriceCellHeader>
              <StyledMoneyPercentHeader title="The change between the closing price of Yesterday and Today">
                Change (%)
              </StyledMoneyPercentHeader>
              <StyledMoneyCellHeader title="The market cap">Mrkt Cap.</StyledMoneyCellHeader>
            </tr>
          </Tables.Head>
          <Tables.Body>
            {shorts.shorts
              .filter((t, index) => index < 10 || t.shortInterestPercent > 30)
              .map((t, index) => (
                <tr key={t.symbol}>
                  <StyledRankCell>{index + 1}.</StyledRankCell>
                  <StyledTickerNameCell title={t.description}>
                    <TickerSymbolAndName ticker={t} />
                  </StyledTickerNameCell>
                  <StyledExchangeCell title={`${t.exchangeName || ''} (${t.exchange || ''})`}>
                    {formatExchangeName(t.exchangeName || '')}
                  </StyledExchangeCell>
                  <StyledMoneyCellLeft>
                    <b>{t.shortInterestPercent}% </b>
                    <PercentChangePercent value={t.shortInterestPercent} prev={t.previousShortInterestPercent || t.shortInterestPercent} />
                  </StyledMoneyCellLeft>
                  <StyledMoneyCell>{t.float}</StyledMoneyCell>
                  <StyledMoneyCell>{t.outstanding}</StyledMoneyCell>
                  <StyledPriceCell>
                    <span>
                      {t.currencySymbol} {fixFormattedPrice(t.priceFormatted || '')}
                    </span>
                  </StyledPriceCell>
                  <StyledPriceChangeCell>
                    <PriceChangePercent value={t.price} prev={t.previousClosingPrice || ''} />
                  </StyledPriceChangeCell>
                  <StyledMoneyCell>
                    {t.marketCap && (
                      <>
                        {t.currencySymbol} {t.marketCap}
                      </>
                    )}
                  </StyledMoneyCell>
                </tr>
              ))}
          </Tables.Body>
        </Tables.Table>
        <StyleLastUpdatedBlock>Last Updated: {shorts.lastUpdatedEst}</StyleLastUpdatedBlock>
        <GammaSection gammas={gammas} />
        <p>
          <br />
        </p>
        {/* <StyledQuoteBlock>
          <br />
          "Share prices aren't really based on how a company works it's about how mass psychology works" --Eddie Morra
        </StyledQuoteBlock> */}
      </Container>
    </Page>
  </IndexLayout>
);

export default HomeTemplate;
